import React, { Component, useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as RiIcons from "react-icons/ri";
import '../journal/journal-voucher.css';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

function Contra(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [voucherno, setVoucherno] = useState("");
    const [invoNum, setInvoNu] = React.useState('')
    const [refno, setRefno] = React.useState("");
    const [registerDate, setRegisterdate] = React.useState(new Date);
    const [refdate, setRefdate] = React.useState(new Date);
    const [tableRow, setTableRow] = React.useState([{ key: "", jd_code: "", account_name: "", jd_account_id_fk: "", jd_debit: "0", jd_credit: "0", jd_narration: "", creditdisabled: false, debitdisabled: false, jd_type: "ledger" }]);
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [codelist, setCodelist] = React.useState([])
    const inputRef = useRef();
    const resultsRef = useRef();
    const [modal, setModal] = React.useState(false);
    const [editmode, setEditmode] = React.useState(false);
    const { id } = useParams()
    const [billType, setBilltype] = React.useState('');
    const [code, setCode] = useState("");
    const [journalId, setJournalid] = useState("");

    const [remarks, setRemarks] = useState("");
    const [userId, setUserid] = useState("");
    const [debitTotal, setDebittotal] = useState(0);
    const [creditTotal, setCredittotal] = useState(0);
    const hasResults = code.length > 0;


    const addRow = () => {
        setTableRow([...tableRow, { key: "", jd_code: "", account_name: "", jd_account_id_fk: "", jd_debit: "0", jd_credit: "0", jd_narration: "", creditdisabled: false, debitdisabled: false, jd_type: "ledger" }])
    }

    useEffect(() => {
        getInvoiceno(billType)
        // console.log('billType', billType)
    }, [billType])

    useEffect(() => {

        calcTotal()
    }, [tableRow]);

    useEffect(() => {
        console.log("id", id)
        getBilltype()
        if (id == "add") {
            // getInvoiceno()
            setEditmode(false)
        }
        else {
            getDetailsbyid(id)
            setEditmode(true)
        }
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    }, []);



    const getDetailsbyid = (id) => {
        console.log("id", id)
        const data = {
            "journal_id": id,
        }
        fetch(global.url + "viewJournal", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("journal head", responseJson)
                if (responseJson.status == "false") {
                    console.log("journal data", responseJson.data)
                    setVoucherno(responseJson.data.journal_voucher_no)
                    setRefno(responseJson.data.journal_ref_no)

                    var invoiceDate = new Date(responseJson.data.journal_date).toISOString();
                    var referenedate = new Date(responseJson.data.journal_ref_date).toISOString();
                    setRegisterdate(invoiceDate)
                    setRefdate(referenedate)
                    setTableRow(responseJson.details)
                    setRemarks(responseJson.data.journal_remarks)
                    setJournalid(responseJson.data.journal_id)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getInvoiceno = () => {
        var data = {
            "id": billType
        }
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                if (responseJson.error == false) {
                    setVoucherno(responseJson.data)
                    setInvoNu(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const calcTotal = () => {
        var debitTotal = 0;
        var creditTotal = 0
        for (var i = 0; i < tableRow.length; i++) {
            debitTotal += parseInt(tableRow[i].jd_debit)
            creditTotal += parseInt(tableRow[i].jd_credit)
        }
        setDebittotal(debitTotal)
        setCredittotal(creditTotal)
    }

    const getSearch = (text) => {
        var data = {
            "account_code": text,
        }

        fetch(global.url + "searchJournal", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("search response", responseJson)
                setCodelist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const debitChange = (event, key) => {
        var input = [...tableRow]
        input[key].jd_debit = event.target.value
        input[key].creditdisabled = true
        input[key].debitdisabled = false
        if (event.target.value == "") {
            input[key].creditdisabled = false
        }
        setTableRow(input)
    }

    const creditChange = (event, key) => {
        var input = [...tableRow]
        input[key].jd_credit = event.target.value
        input[key].creditdisabled = false
        input[key].debitdisabled = true
        if (event.target.value == "") {
            input[key].debitdisabled = false
        }
        setTableRow(input)
    }

    const narrationChange = (event, key) => {
        var input = [...tableRow]
        input[key].jd_narration = event.target.value
        setTableRow(input)
    }

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
    };

    const inputHandler = (text, key) => {

    }

    const onSubmit = () => {

        if (debitTotal == creditTotal) {

            const exampleArray = []
            for (var i = 0; i < tableRow.length; i++) {
                if (tableRow[i].jd_account_id_fk == "") {
                    // tableRow.splice(i, 1)
                }
                else {
                    exampleArray.push(tableRow[i])
                }

            }
            var paymentdate;
            var refdate1;
            if (editmode == true) {
                paymentdate = registerDate.split('T')[0]
                refdate1 = refdate.split('T')[0]
            }
            else {
                paymentdate = registerDate.toISOString().split('T')[0]
                refdate1 = refdate.toISOString().split('T')[0]
            }
            if (remarks == "") {
                alert("Remarks is required")
            }


            if (exampleArray.length == 2) {
                const data = {
                    "user_id": userId,
                    "voucher_no": voucherno,
                    "reference_no": refno,
                    "journal_date": paymentdate,
                    "reference_date": refdate1,
                    "journal_remarks": remarks,
                    "journal_table": exampleArray,
                    "journal_id": journalId,
                    "journal_branchid": userbranchid,
                    "journal_companyid": usercompanyid,
                    "journal_type": 2,
                    "journal_bill_type": billType
                }
                console.log("data", data)
                fetch(global.url + "updateJournal", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        console.log("response", responseJson)
                        if (responseJson.error == false) {
                            alert(responseJson.message)
                            window.location.reload();
                        }
                        else {
                            alert(responseJson.data)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }
            else {
                alert("Only 2 entry possible")
            }


        }
        else {
            alert("debit and credit no equal")
        }
    }

    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "id": 8
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
                setBilltype(responseJson.data[0].billset_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const listClickcode = (index, code, name, id) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].jd_code = code
        input[index].jd_account_id_fk = id
        input[index].account_name = name
        setTableRow(input)
        addRow()
        setModal(false)
    }

    const modelOpen = (index) => {
        setSelectedindex(index)
        getSearch("")
        setModal(true)
    }

    const upClick = () => {
        setVoucherno(preCount => preCount + 1)
    }

    const downClick = () => {
        if (voucherno > 1) {
            setVoucherno(preCount => preCount - 1)
        }
    }
    const purchaseDetails = () => {
        getPurchaseDetails()
    }

    const deleteClick = (index, id, invoice) => {
        // deleteFromApi(id, invoice)
        var input = [...tableRow]
        input.splice(index, 1)
        setTableRow(input)
    }

    // const deleteFromApi = (id, invoice) => {
    //     if(window.confirm('Are you Want to delete this ? It will be deleted from your database')){
    //     var userdata = JSON.parse(localStorage.getItem("user"))
    //     const data = {
    //         "jd_id" : id,
    //         "invoice": invoice,
    //         "userid": userdata.user_id
    //     }
    //     console.log("data detid", data)
    //     fetch(global.url + "deletecontrainEdit", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         console.log("del respo", responseJson)
    //         if(responseJson.error == true) {
    //             alert(responseJson.message)
    //         }
    //         else{
    //             alert(responseJson.message)
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    //     }else{
    //         window.location.reload()
    //     }
    // }

    const getPurchaseDetails = () => {
        var data = {
            'voucher_no': voucherno,
            'journal_type': 1
        }
        console.log("data jou", data)
        fetch(global.url + "JournaldetailByVoucherno", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("responseJson journal", responseJson)
                if (responseJson.error == false) {
                    setRefno(responseJson.data.refno)
                    setRefdate(responseJson.data.refdate)
                    setRegisterdate(responseJson.data.date)
                    if (responseJson.data.details) {
                        setTableRow(responseJson.data.details)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="main-content">
            <div className='bg12' style={{  width: '100%', padding: 10, display: 'flex' }}>
                <img src={require("../../../assets/images/icons/jv.svg").default} />
                <p style={{ marginBottom: 'unset', color: '#fff', fontWeight: 'bold', marginLeft: 10 }}>Contra</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col md={2} style={{ marginTop: 30 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        size="small"
                                        label="Date"
                                        value={registerDate}
                                        onChange={(newValue) => {
                                            setRegisterdate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{ marginTop: 30 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={billType}
                                    label="Age"
                                    onChange={billtypeChange}
                                    size="small"

                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                        return (
                                            <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{ marginTop: 30 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField disabled value={voucherno} onChange={(e) => setVoucherno(e.target.value)} id="outlined-basic" label="Voucher No" variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                        <Col md={2} style={{ marginTop: 30 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField value={refno} onChange={(e) => setRefno(e.target.value)} id="outlined-basic" label="Ref No" variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{ marginTop: 30 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        size="small"
                                        label="Ref Date"
                                        value={refdate}
                                        onChange={(newValue) => {
                                            setRefdate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col xs={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>SlNo</th>
                                <th>Code</th>
                                <th>Account</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Narration</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td onClick={() => modelOpen(index)}>{item.jd_code}</td>
                                        <td>{item.account_name}</td>
                                        <td><input autocomplete="off" type="number" disabled={item.debitdisabled == true ? true : false} value={item.jd_debit} onChange={(event) => debitChange(event, index)} /></td>
                                        <td><input autocomplete="off" type="number" disabled={item.creditdisabled == true ? true : false} value={item.jd_credit} onChange={(event) => creditChange(event, index)} /></td>
                                        <td><input autocomplete="off" type="text" value={item.jd_narration} onChange={(event) => narrationChange(event, index)} /></td>
                                        <td>
                                            <a href="javascript:void(0)" onClick={() => deleteClick(index, item.jd_id, item.Contra_voucher_no)}>
                                                <RiIcons.RiDeleteBin7Fill color="red" fontSize={20} />
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan={3}>Total</td>
                                <td>{debitTotal}</td>
                                <td>{creditTotal}</td>
                                <td></td>
                            </tr>

                        </tbody>
                    </Table>
                    <Button onClick={() => addRow()} variant="outlined">Add</Button>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col xs={12}>
                    <TextareaAutosize
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        minRows={3}
                        placeholder="Remarks"
                        style={{ background: 'transparent', width: '100%' }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col xs={12} >
                    <ul className="list-inline">
                        <li><Button className='btn1'  onClick={() => onSubmit()}  variant="outlined"><IconName.BsDownload style={{ marginRight: 10 }} />Save</Button></li>
                        <li><Button className='btn1' onClick={purchaseDetails}  variant="outlined"><GI.GiBinoculars style={{ marginRight: 10 }} />Find</Button></li>
                        {/* <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                                <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                                <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li> */}
                    </ul>
                </Col>
            </Row>

            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>

                            <ul>
                                {codelist.map((row, index) => {
                                    return (
                                        <a style={{ textDecoration: 'none', listStyle: 'none' }} href="javascript:void(0)" onClick={() => listClickcode(selectedIndex,
                                            row.ledger_code,
                                            row.ledger_name,
                                            row.ledger_id,)}>
                                            {/* <li >{row.ledger_code}</li> */}
                                            <li style={{ color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer' }} >{row.ledger_name}</li>
                                        </a>
                                    )
                                }
                                )
                                }
                            </ul>
                        </Col>

                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Contra;
