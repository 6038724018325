import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';

const columns = [
    { id: '1', label: 'Category', minWidth: 50,span: false },
    { id: '2', label: 'Descripton', minWidth: 50,span: false },
    { id: '4', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [source, setSource] = React.useState([]);

    const [loader, setLoader] = React.useState(false);

    const [editmode, setEditmode] = React.useState(false);

    const [userId, setUserid] = React.useState('');
    const [categoryId, setCategoryid] = React.useState("");
    const [categoryName, setCategoryname] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);
    const [image, setImage] = React.useState();
    const [imageurl, setImageurl] = React.useState("");
    const [checked, setChecked] = React.useState(true);
    const [selectedindex, setSelectedindex] = React.useState();
    const [viewtype, setViewtype] = React.useState(0);
    const [department, setDepartment] = React.useState("");
    const [departmentList, setDepartmentList] = React.useState([]);
    const [section, setSection] = React.useState("");
    const [sectionList, setSectionList] = React.useState([]);

    const departmentChange = (event) => {
      setDepartment(event.target.value);
    };

    const sectionChange = (event) => {
      setSection(event.target.value);
    };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
    getData()
  }, []);

  const getDepartment = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewBrand", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        
        if(responseJson.status == "true"){
          setDepartmentList([])
        }
        else{
          setDepartmentList(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getSection = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewItemgroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        // console.log("section list", responseJson)
        if(responseJson.status == "true"){
          setSectionList([])
        }
        else{
          setSectionList(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)

    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.status == "false") {
            setSource(responseJson.data)
            if(responseJson.data[0].category_grid == 0) {
              setChecked(false)
            }
            else{
              setChecked(true)
            }
            console.log("response", responseJson.data)
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const editClick = (
    categoryid, 
    categoryname,
    description,
    blocked,
    image,
    index
    ) => {
      setModal(true)
      setEditmode(true)
      setCategoryid(categoryid)
      setCategoryname(categoryname)
      setDescription(description)
      setBlocked(blocked)
      setImageurl(image)
      setSelectedindex(index)
      getDepartment()
      getSection()
      console.log("image", image)
  }

  const onAdd = () => {
      setModal(true)
      getDepartment()
      getSection()
      setEditmode(false)
      setCategoryid("")
      setCategoryname("")
      setDescription("")
      setBlocked(0)
      setImageurl("")
  }

  const onSave = () => {


    const formData = new FormData();
    formData.append('category_id', categoryId);
    formData.append('category_userid', userId);
    formData.append('category_name', categoryName);
    formData.append('category_description', description);
    formData.append('category_blocked', blocked);
    formData.append('category_branchid', userbranchid);
    formData.append('category_companyid', usercompanyid);
    formData.append('category_image', image);

    console.log('category_image', image)

    fetch(global.url + "updateCategory", {
        method: "POST",
        body: formData,
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.error == false) {
          getData()
          setModal(false)
          alert(responseJson.message)
        }
        if(responseJson.error == true) {
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const deleteClick = (id) =>  {
    var data = {
      "category_id": id,
    }
    fetch(global.url + "deleteCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.message)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const uploadImage = (e) => {
    console.log("files", e.target.files[0])
    setImage(e.target.files[0])
  }

  const deleteImage = () => {
    var data = {
      "category_id": categoryId,
    }
    fetch(global.url + "deleteImageCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.error == false){
          alert(responseJson.message)
          setImageurl("")
        }
        else{

        }
        console.log("delete image response", responseJson)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const addImage = () => {

    const formData = new FormData();
    formData.append('category_image', image);
    formData.append('category_id', categoryId);
    console.log("categoryId", categoryId)
    fetch(global.url + "addImageCategory", {
        method: "POST",
        body: formData,
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("save image response", responseJson)
        if(responseJson.error == false){
           alert(responseJson.message)
           setImageurl(responseJson.data)
        }
        else{
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const changeView = (event) => {
    if(event.target.checked == true){
        var data = {
          "cvtype": 1,
        }
        fetch(global.url + "CategoryViewType", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("response", responseJson)
          if(responseJson.error == false) {
            alert("Changed to grid view")
          }
          else{
            alert(responseJson.message)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    else{
      var data = {
        "cvtype": 0,
      }
      fetch(global.url + "CategoryViewType", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("response", responseJson)
          if(responseJson.error == false) {
            alert("Changed to Normal view")
          }
          else{
            alert(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }
    setChecked(event.target.checked);
  };


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Category</p>
               {/* <FormGroup>
                  <FormControlLabel control={<Switch onChange={changeView}  checked={checked}/>} label="Grid View" />
                </FormGroup> */}
               {
                      usertype == "superadmin" ? 
                      null : 
                    <Button variant="contained" onClick={onAdd} className='btn-products'>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                    {source.map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.category_name}
                              </TableCell>
                              <TableCell >
                                  {row.category_description}
                              </TableCell>
                              <TableCell >
                                  {row.category_blocked == "0" ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                              {
                                  usertype == "superadmin" ? 
                                  null : 
                                  <a href="javascript:void(0)" onClick={() => 
                                    editClick(row.category_id, 
                                      row.category_name, 
                                      row.category_description, 
                                      row.category_blocked, 
                                      row.category_image,
                                      index
                                    )}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.category_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
                
              </Table>
              }
            </TableContainer>
            
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Category Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={department}
                                label="Age"
                                onChange={departmentChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {departmentList.map((item, index) => (
                                  <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={section}
                                label="Age"
                                onChange={sectionChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {sectionList.map((item, index) => (
                                  <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setCategoryname(e.target.value)} value={categoryName} id="outlined-basic" label="Category" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>

                    <Col xs={12} >
                      {
                        selectedindex == 0 ? 
                        <label>Image Size: (423 * 242)</label>: 
                        selectedindex == 1 ?
                        <label>Image Size: (423 * 171)</label>: 
                        selectedindex == 2 ?
                        <label>Image Size: (423 * 256)</label>: 
                        selectedindex == 3 ?
                        <label>Image Size: (423 * 153)</label>: 
                        selectedindex == 4 ?
                        <label>Image Size: (423 * 224)</label>: 
                        selectedindex == 5 ?
                        <label>Image Size: (423 * 261)</label>: 
                        selectedindex == 6 ?
                        <label>Image Size: (423 * 237)</label>: 
                        selectedindex == 7 ?
                        <label>Image Size: (423 * 155)</label>: 
                        selectedindex == 8 ?
                        <label>Image Size: (423 * 155)</label>: 
                        selectedindex == 9 ?
                        <label>Image Size: (423 * 155)</label>: 
                        selectedindex == 10 ?
                        <label>Image Size: (423 * 237)</label>: 
                        selectedindex == 11 ?
                        <label>Image Size: (423 * 155)</label>: 
                        null
                      }

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setDescription(e.target.value)} value={description} id="outlined-basic" label="Description" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    {/* {
                      editmode && imageurl != "" &&  imageurl != null ? 
                      <Col xs={12} style={{marginTop: 10}}>
                      
                        <img src={global.images + "ecommerce_photos/" + imageurl} style={{width: 60, height: 60}} /> 
                        <Button onClick={deleteImage}  variant="contained">Delete</Button>
                      </Col> : editmode &&  (imageurl == "" || imageurl == null) ?
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                          <Button onClick={addImage}  variant="contained">Add Image</Button>
                      </Col> :
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                      </Col>
                    }
                     */}
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                                } label="Blocked" />
                            </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;