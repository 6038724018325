import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";

function TransferOut(props) {

    const inputRef = useRef();
    const resultsRef = useRef();
    const inputRef1 = useRef();

    const [billtypelist, setBilltypelist] = React.useState([]);
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [branchlist, setBranchlist] = useState([]);
    const [branch2, setBranch2] = useState("");
    const [date, setDate] = React.useState(new Date)
    const [addnew, setAddnew] = React.useState(false);
    const [tableRow,setTableRow] = React.useState([{key:"", batchid_from: "", batchid_to: "", pbexp_idfrom: "", pbexp_idto: "", stocktransfered: "", pb_expiry_date: '', product_batch_id:"",  product_id: "", code: "", barcode: "",  productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "", addnew: true,}])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [code, setCode] = useState("");
    const [code2, setCode2] = useState("");
    const [productlist, setProductlist] = React.useState([]);
    const [productlist2, setProductlist2] = React.useState([]);
    const [productname, setProductname] = useState("")
    const [productname2, setProductname2] = useState("")
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");
    const [total, setTotal] = React.useState(0);
    const [userId, setUserid] = React.useState("")
    const [errormsg, seterrormsg] = React.useState('');
    const [expirydatelist, setExpirydatelist] = React.useState([]);
    const [indexState, setIndexstate] = React.useState(0)
    const hasResults = code.length > 0;
    const hasResults2 = code2.length > 0;
    const hasResultsProduct = productname.length > 0;
    const hasResultsProduct2 = productname2.length > 0;

    const [transExpto, setTransexpto] = React.useState("")
    const [transExpfrom, setTransexpfrom] = React.useState("")
    const [lookupproductlist, setLookupproductlist] = React.useState([]);
    const [productmodal, setProductModal] = useState(false)

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
      };
    
      const branchselect = (event) => {
        setBranch2(event.target.value)
       
    }

    const getexpid1 = (id, index) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        const data = {
            "branchid" : branch,
            "pb_id" : id
        }
        console.log("data brancg", data)
        fetch(global.url + "getProductbybranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response exp id", responseJson)
            if(responseJson.error == false){
                // setTransexpfrom(responseJson.data[0].pbexp_id)
                var input = [...tableRow]
                input[index].pbexp_idfrom = responseJson.data[0].pbexp_id
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getexpid2 = (id, index) => {
        const data = {
            "branchid" : branch2,
            "pb_id" : id
        }
        console.log("data brancg", data)
        fetch(global.url + "getProductbybranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response exp id", responseJson)
            if(responseJson.error == false){
                // setTransexpfrom(responseJson.data[0].pbexp_id)
                var input = [...tableRow]
                input[index].pbexp_idto = responseJson.data[0].pbexp_id
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const colorChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        // getExpiry(event.target.value)
        setTableRow(input)
        
        // getProductbybatch1(event.target.value, selectedIndex)
      //   setColor(event.target.value)
    }

    const dateChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].pbexp_id = event.target.value
        setTableRow(input)
    }

    const colorChange2 = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id2 = event.target.value
        setTableRow(input)
        
        // getProductbybatch2(event.target.value, selectedIndex)
      //   setColor(event.target.value)
    }

    // const getExpiry = (id) => {
    //     var data = {
    //         "pb_id" : id,
    //     }
    //     console.log("data", data)
    //     fetch(global.url + "getExpiry", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         console.log("expiry", responseJson)
    //         setExpirydatelist(responseJson.data)
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        input[index].batchid_from = id
        input[index].batchid_to = id
        input[index].pb_stock = responseJson.pb_stock
        input[index].pb_expiry_date = responseJson.pb_expiry_date
        // input[index].pbexp_idfrom = transExpfrom 
        // input[index].pbexp_idto = transExpto 
        input[index].sod_qty = 1
        input[index].stocktransfered = 1
        input[index].unit = responseJson.unit_name
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].pei_image = responseJson.pei_image
        
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0
        input[index].sod_total_amount = responseJson.pb_salesrate 
            setTableRow(input)
            addRow()
    }

    useEffect(() => {
        getInvoiceno(billType)
    },[billType])


      useEffect(() => {      
        getBilltype()
        getBranch()
      }, []);

      const getBranch = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        const data = {
            "company_id": company
        }
        fetch(global.url + "viewAllBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("branch list response", responseJson)
            setBranchlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const deleteRow = (index, price, sodid) => {
        var exarray = []
        for(var i = 0; i < tableRow.length; i++) {
            if(tableRow[i].sod_iscancel != 1) {
              exarray.push(tableRow[i])
            }
        }
        if(exarray.length == 1){
            alert("Cann't cancel 1 item bill, Please Cancel the Order in salesapproval")
            exarray = []
            return
        }
        exarray = []
        if(sodid == "") {
          console.log("index", index)
          setTotal(total-price)
          var input = [...tableRow]
          input.splice(index, 1)
          console.log("input", input)
          setTableRow(input)
        }
        else{
          var data = {
              "sod_id":  sodid,
              "user_id": userId
          }
          console.log("data", data)
          fetch(global.url + "salesOrderEditProductDelete", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response", responseJson)
                window.location.reload()
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }
      
    }

      const getBilltype = () => {

        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "id" : 14
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
              setBilltype(responseJson.data[0].billset_id)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getInvoiceno = (id) => {
        var data = {
            "id" : billType
        }
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("purchaseinvoiceno", responseJson.data.invoiceno)
              
              if(responseJson.error == false){
                setInvoice(responseJson.data)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)
    }

    const getSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        console.log("searchdata", data)
        
        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            listClickrefcode(
                key, 
                responseJson.data[0].products_id,
                responseJson.data[0].pb_expiry_date,
                responseJson.data[0].products[0].pb_inter_barcode,
                responseJson.data[0].products[0].pb_id,
                responseJson.data[0].product_refcode,
                responseJson.data[0].product_name,
                responseJson.data[0].products,
                0
            )
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const listClickrefcode = (index, productid, expdate, pb_inter_barcode, pbid, productrefcode,productname,products, iscombo) => {
          var input = [...tableRow]
          input[index].key = index

          input[index].products_id = productid
          input[index].code = productrefcode
          input[index].pb_expiry_date = expdate
          input[index].sales_iscombo = iscombo
          input[index].productname = productname
          input[index].barcode = pb_inter_barcode
          input[index].addnew = true
        //   getExpiry(pbid)
            getexpid1(pbid, index)
            getexpid2(pbid, index)
          getProductbybatch1(pbid, index)
          setSelectedindex(index)
          setTableRow([...tableRow, input])
          setTableRow([...tableRow, {key:"", batchid_from: "", batchid_to: "", pb_expiry_date: "", pbexp_idfrom: "", pbexp_idto: "", stocktransfered: "", product_batch_id:"", product_id: "", code: "", barcode: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "", addnew: true }])
          setCode("")
      }

  const getProductbybatch1 = (id, index) => {
   
    var data = {
        "pb_id" : id,
    }
    console.log("data", data)
    fetch(global.url + "getProductBatchById", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("setDetails", responseJson)
        setDetails(id, index, responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

const qtyChange = (event, index) => {
    var textArray1 = [...tableRow]
    console.log("price", textArray1[index].price)
    var totalprice = textArray1[index].price * event.target.value
    textArray1[index].sod_qty = event.target.value
    textArray1[index].sod_total_amount = totalprice
    setTableRow(textArray1)
}

const formatDate = (datestring) => {
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
      
      var date = new Date(datestring)
      var month = pad2(date.getMonth()+1);//months (0-11)
      var day = pad2(date.getDate());//day (1-31)
      var year= date.getFullYear();

      const timeString12hr = new Date(datestring)
      .toLocaleTimeString('en-US',
          {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
      );
    //   console.log("timeString12hr", timeString12hr)

      var formattedDate =  year + "-" + month + "-" + day;
      return formattedDate; //03/02/2021
}

const update = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    var userdata = JSON.parse(localStorage.getItem("user"))
    console.log("sj123",tableRow )
    const exampleArray = []
            var exampleSubarray = false
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "" || tableRow[i].sales_isedit == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    console.log("fetchproducts1",tableRow[i].fetch_products)
                    if(tableRow[i].fetch_products != undefined){
                        for(var j = 0 ; j< tableRow[i].fetch_products.length; j++) {

                            if(tableRow[i].fetch_products[j].fetch_id == "" && tableRow[i].sales_iscombo == 1){
                                alert("Empty Product in Combo product, Cannot Save Order")
                                exampleSubarray = true
                            }
                            else{
                                
                            }
                        }
                    }
                    
                    exampleArray.push(tableRow[i])
                } 
            }
            var datenw = formatDate(date)
            var data = {
                "voucherno": invoice,
                "refno": refno,
                "remarks": remarks,
                "companyid": company,
                "userid": userdata.user_id,
                "branchid": branch,
                "transfer_date": datenw,
                "transfer_branchid": branch2,
                "transfer_products": exampleArray,
            }
            console.log("data stock", data)
            fetch(global.url + "stockTransferout", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response stock", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.message)
                   
                }
                else{
                    alert(responseJson.message)
                    window.location.reload()
                }
            })
            .catch((error)=>{
                console.log(error)
            })
}

const addRow = () => {
    setAddnew(true)
    setTableRow([...tableRow, {key:"",  batchid_from: "", batchid_to: "", pbexp_idfrom: "", pbexp_idto: "", stocktransfered: "", pb_expiry_date: "", product_batch_id:"", code: "",  barcode: "",  productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "",  addnew: true}])
}
const indexChange = (index) => {
    console.log("index", index)
    setIndexstate(index)
}

const getbarcodename = (id) => {
    setIndexstate(indexState + 1)
    console.log("index", indexState)
    setProductModal(false)
    getSearch(id, "refcode", indexState)
 }

const lookupClick = () => {
    setProductModal(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))

    var data = {
      "usertype": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewAllproductbatch", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("item look up", responseJson)
          setLookupproductlist(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
}
const onfilter = (text, type) => {
    var company = JSON.parse(localStorage.getItem("company"))
    if(type == "intercode") {
        var data = {
            "type" : 'intercode',
            "intercode":  text
        }
        fetch(global.url + "posGetSearch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response combo", responseJson)
            if(responseJson.error == false){
                setLookupproductlist(responseJson.data)
            }else{
                setLookupproductlist([])
                
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    if(type == "superadmin") {
        var data = {
            "type" : 'superadmin',
            "productname":  text,
            "companyid" : company
        }
        console.log("superadmin", data)
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response combo", responseJson)
            if(responseJson.status == 'false'){
                setLookupproductlist(responseJson.data)
            }else{
                setLookupproductlist([])
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
}
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Transfer Out</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} md={2} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Doc Date"
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        </Col>
                        <Col sm={6} md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                        return (
                                            <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                        )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField label="Voucher No." disabled value={invoice} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Transfer Branch</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={branch2}
                                    label="Age"
                                    onChange={branchselect}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {branchlist.map((item, index) => (
                                    <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField label="Ref No." value={refno}  onChange={(e) => setRefno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} md={2} style={{marginTop:30}}>
                            <Button  onClick={() => lookupClick()}  className="retail-btn" style={{ borderRadius: 5, float: 'right'}} variant="contained">Item Lookup</Button>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                        <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th>Ref Code</th>
                                        <th>Barcode</th>
                                        <th>Product name</th>
                                        {/* <th>Order Qty</th> */}
                                        <th>Qty</th>
                                        <th>Unit</th>
                                        <th>Price</th>
                                        <th>Total Amount</th>
                                        {/* <th>Sales Price</th> */}
                                        <th>Expiry</th>
                                        <th>Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr>
                                                <td >{index+1}</td>
                                                <td>
                                                    <input style={{width: 100, border: 0, outline: 0}} onFocus={() => indexChange(index)} autoFocus autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                </td>
                                                <td>
                                                    {item.barcode} 
                                                </td>
                                                <td>
                                                    <p style={{width: 300}}>{item.productname}</p>
                                                </td>
                                                {/* <td>{item.pd_qty}</td> */}
                                                <td>
                                                <input style={{width: 50, border: 0, outline: 0}} autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />
                                                </td>
                                                <td>{item.unit}</td>
                                                <td>{item.price}</td>
                                                <td>{parseFloat(item.sod_total_amount).toFixed(2)}</td>
                                                {/* <td>
                                                    {parseFloat(item.salesrate).toFixed(2)}
                                                </td> */}
                                                <td style={{width: 200}}>
                                                    {/* <div style={{width: 200}}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                value={expiry}
                                                                onChange={(newValue, index) => {
                                                                    ExpiryChange(newValue, index);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div> */}
                                                    {item.pb_expiry_date}
                                                </td>
                                                <td><Button onClick={() => deleteRow(index, item.pd_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#fc0303" fontSize={30} style={{marginRight: 10}} /></Button></td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>
                            </Table>
                    {/* <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button> */}
                </Col>
            </Row>                         

            <div style={{marginTop: 30}}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={6}>
                        <TextareaAutosize 
                            value={remarks}  
                            onChange={(e) => setRemarks(e.target.value)}
                            minRows={3}
                            placeholder="Remarks"
                            style={{background: 'transparent', width: '100%'}}
                        />
                    </Col>
                </Row>
                
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <ul className="list-inline">
                            <li><Button className='btn1' onClick={update} variant="outlined" style={{border: 'none'}}><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                            {/* <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li> */}
                        </ul>
                    </Col>
                </Row>
            </div>
          </Paper> 
          <Modal
                size="lg"
                show={productmodal}
                onHide={() => setProductModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Product List
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined"  size="small" label="Barcode"/>
                        </Col>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined"  size="small" label="Product Name"/>
                        </Col>
                        {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                    </Row>
                    <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Ref Code</th>
                                <th>Barcode</th>
                                <th>Product Name</th>
                                <th>Stock</th>
                                <th>Sales Price</th>
                            </tr>
                        </thead>
                        {/* {productlistitem.length == 0 ? */}
                            <tbody>
                                {lookupproductlist?.map((column) => (
                                    <tr>
                                        <td onClick={() => getbarcodename(column.product_refcode)} style={{cursor: 'pointer'}}>{column.product_refcode}</td>
                                        <td onClick={() => getbarcodename(column.product_refcode)} style={{cursor: 'pointer'}} >{column.pb_inter_barcode}</td>
                                        <td onClick={() => getbarcodename(column.product_refcode)} style={{cursor: 'pointer'}}>{column.product_name}</td>
                                        <td>{column.pb_stock}</td>
                                        <td>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody>
                        </Table>
                    </Col>
                    </Row>
                </Modal.Body>
            </Modal>
      </div>
    )
}
export default TransferOut;