import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Form } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';

const columns = [
    { id: '1', label: 'Bank', minWidth: 50,span: false },
    { id: '2', label: 'Bank Code', minWidth: 50,span: false },
    { id: '4', label: 'Account Holder', minWidth: 50,span: false },
    { id: '5', label: 'IFSC', minWidth: 50,span: false },
    { id: '6', label: 'Branch', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  
  
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [bankmasterId, setBankmasterid] = React.useState("");
    const [bankmasteruserid, setBankmasteruserid] = React.useState()
    const [bankCode, setBankcode] = React.useState("");
    const [groupName, setGroupname] = React.useState();
    const [accountHolder, setAccountholder] = React.useState("");
    const [ifsc, setIfsc] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setddress] = React.useState("");
    const [contact, setContact] = React.useState("");
    const [accountNo, setAccountno] = React.useState("");
    const [accountType, setAccounttype] = React.useState("");
    const [accountId, setAccountid] = React.useState(0);
    const [district, setDistrict] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);
    const [bank, setBank] = React.useState("");
    const [crdr, setCrdr] = React.useState(0);
    const [opening, setOpening] = React.useState(0);
    const [branchName, setBranchname] = React.useState("");
    const [userId, setUserid] = React.useState("");

    const [countryList, setCountryList] = React.useState([]);
    const [stateList, setStatelist] = React.useState([]);
    const [districtList, setDistrictlist] = React.useState([]);
    const [bankList, setBanklist] = React.useState([]);
    const [group, setGroup] = React.useState("");
    const [groupList, setGrouplist] = React.useState([]);

    
  const typeChange = (event) => {
    setAccounttype(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const groupChange =(event) => {
    setGroup(event.target.value)
  }

  useEffect(() => {
    getData()
    getGroup()
  }, []);

  const editClick = (
    bankmasterid, 
    bankmasteruserid,
    bank,
    bankcode,
    groupname,
    accountholder,
    ifsc,
    branchname,
    country,
    state,
    district,
    address,
    contact,
    acctype,
    accountno,
    creditDebit,
    opening,
    ) => {
      console.log("groupname",groupname)
      setModal(true)
      setBankmasterid(bankmasterid)
      setBankmasteruserid(bankmasteruserid)
      setBank(bank)
      setBankcode(bankcode)
      setGroup(groupname)
      setAccountholder(accountholder)
      setIfsc(ifsc)
      setBranchname(branchname)
      setCountry(country)
      setState(state)
      setddress(address)
      setContact(contact)
      setAccountno(accountno)
      setAccounttype(acctype)
      setDistrict(district)
      setCrdr(creditDebit)
      setOpening(opening)
      getCountry()
      getState(country)
      getDistrict(state)
      getBank()
  }

    const onAdd = () => {
      setModal(true)
      setBankmasterid("")
      setBankcode("")
      setAccountholder("")
      setIfsc("")
      setGroup("")
      setCountry("")
      setState("")
      setddress("")
      setContact("")
      setAccountno("")
      setAccounttype("")
      setAccountid("")
      setDistrict("")
      setBlocked("")
      setBank("")
      setCrdr(0)
      setOpening(0)
      setBranchname("")
      getCountry()
      getBank()
      setGroup()
  }


  const getBank = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "usertype": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewBank", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log('bank response', responseJson)
      if(responseJson.status == "false"){
        setBanklist(responseJson.data)
      }
        
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getCountry = () => {
      fetch(global.url + "viewCountry", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          if(responseJson.status == "false") {
            setCountryList(responseJson.data)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const getState = (id) => {
        var data = {
          "countryid": id
        }
        fetch(global.url + "viewState", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "false") {
              setStatelist(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const countryChange = (event) => {
    setCountry(event.target.value);
    getState(event.target.value);
  };

  const stateChange = (event) => {
    setState(event.target.value);
    getDistrict(event.target.value)
  };

  const getDistrict = (id) => {
        var data = {
          "countryid": id
        }
        fetch(global.url + "viewDistrict", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log(responseJson)
          if(responseJson.status == "false") {
            setDistrictlist(responseJson.data)
          }
          
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const districtChange = (event) => {
    setDistrict(event.target.value);
  };

  const radioChange = (event) => {
    console.log(event.target.value, "event.target.value")
    if(event.target.value == "credit") {
       setCrdr(1)
    }
    else {
      setCrdr(0)
    }
  }

  const onSave = () =>  {
        var data = {
          "bankmaster_id": bankmasterId,
          "bankmaster_userid": userId,
          "bankmaster_bank": bank,
          "bankmaster_bankcode": bankCode,
          "ledger_group": group,
          "bankmaster_accountholder": accountHolder,
          "bankmaster_ifsc": ifsc,
          "bankmaster_branchname": branchName,
          "bankmaster_country": country,
          "bankmaster_state": state,
          "bankmaster_district": district,
          "bankmaster_address": address,
          "bankmaster_contactnumber": contact,
          "bankmaster_accounttype": accountType,
          "bankmaster_decr": crdr,
          "bankmaster_opening": opening,
          "bankmaster_accno" : accountNo,
          "bankmaster_branchid" : userbranchid,
          "bankmaster_companyid" : usercompanyid,
          "bankmaster_email": "@gmail.com"
        }
          console.log("data", data)
        fetch(global.url + "updateBankmaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("add", responseJson);
            if(responseJson.status == "false") {
              getData()
              setModal(false)
              alert("Item Added")
            }
            if(responseJson.status == "true") {
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const bankChange = (event) => {
        setBank(event.target.value)
      }

      const deleteClick = (id) =>  {
        var data = {
          "bankmaster_id": id,
        }
        fetch(global.url + "deleteBankmaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("del", responseJson)
            alert("Deleted")
            getData()
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const getGroup = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
          "companyid":  company,
          "type":  userdata.user_type
        }
        console.log("ledger data", data)
        fetch(global.url + "viewGroup", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            if(responseJson.status == "false") {
              setGrouplist(responseJson.data)
              console.log("groupresponse", responseJson)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
      }


  const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)

      var data = {
        "usertype": userdata.user_type,
        "companyid": company
      }
      fetch(global.url + "viewBankmaster", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "false") {
              setLoader(false)
              setSource(responseJson.data)
              console.log("response", responseJson.data)
            }
            else{
              setLoader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Bank Master</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button className='btn-products' variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.bankmaster_bank}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.bankmaster_bankcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.bankmaster_accountholder}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.bankmaster_ifsc}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.bankmaster_branchname}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.company}
                              </TableCell>
                              <TableCell >
                              {
                                usertype == "superadmin" ? 
                                null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                      editClick(row.bankmaster_id, 
                                        row.bankmaster_userid, 
                                        row.bankmaster_bank, 
                                        row.bankmaster_bankcode, 
                                        row.group_id, 
                                        row.bankmaster_accountholder, 
                                        row.bankmaster_ifsc,
                                        row.bankmaster_branchname,
                                        row.bankmaster_country,
                                        row.bankmaster_state,
                                        row.bankmaster_district,
                                        row.bankmaster_address,
                                        row.bankmaster_contactnumber,
                                        row.bankmaster_accounttype,
                                        row.bankmaster_decr,
                                        row.bankmaster_opening,
                                        row.bankmaster_accno,
                                        
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.bankmaster_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Bank Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  <Col xs={12}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bank</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={bank}
                                label="Age"
                                onChange={bankChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {bankList.map((item, index) => (
                                  <MenuItem value={item.bank_id}>{item.bank_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                      <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setBankcode(e.target.value)} value={bankCode} id="outlined-basic" label="Bank Code" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}></label>
                    </Col>
                    <Col xs={12} >
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Group</InputLabel>
                              <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={group}
                              label="Age"
                              onChange={groupChange}
                              size="small"
                              
                              >
                              <MenuItem value="">
                              <em>None</em>
                              </MenuItem>
                              {groupList.map((item, index) => (
                                  <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                      <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setAccountholder(e.target.value)} value={accountHolder} id="outlined-basic" label="Account Holder" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setIfsc(e.target.value)} value={ifsc} id="outlined-basic" label="IFSC" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setBranchname(e.target.value)} value={branchName} id="outlined-basic" label="Branch Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={country}
                                label="Age"
                                onChange={countryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {countryList.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">State</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={state}
                                label="Age"
                                onChange={stateChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {stateList.map((item, index) => (
                                  <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">District</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={district}
                                label="Age"
                                onChange={districtChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {districtList.map((item, index) => (
                                  <MenuItem value={item.district_id}>{item.district_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setddress(e.target.value)} value={address} id="outlined-basic" label="Address" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setContact(e.target.value)} value={contact} id="outlined-basic" label="Contact Number" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Account Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={accountType}
                                label="Age"
                                onChange={typeChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Savings">Savings</MenuItem>
                              <MenuItem value="Current">Current</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setAccountno(e.target.value)} value={accountNo} id="outlined-basic" label="Account No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4}} component="fieldset">
                            <RadioGroup
                                defaultValue="credit"
                                name="radio-buttons-group"
                                onClick={radioChange}
                            >
                                <FormControlLabel value="credit"  control={<Radio checked={crdr == 0?  false: true}/>} label="Credit" color = '#999'  />
                                <FormControlLabel value="debit" color= '#999' control={<Radio checked={crdr == 0?  true: false}/>} label="Debit" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField type="number" onChange={(e) => setOpening(e.target.value)} value={opening} id="outlined-basic" label="Opening" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;